import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The following example uses `}<a parentName="p" {...{
        "href": "https://smallstep.com"
      }}>{`smallstep`}</a>{` open-source
products to securely deploy `}<a parentName="p" {...{
        "href": "https://github.com/buoyantio/emojivoto"
      }}>{`Emojivoto`}</a>{`
microservice to AWS using mutual TLS.`}</p>
    <h2>{`About this tutorial`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to use mutual TLS to connect microservices on AWS securely.`}</li>
      <li parentName="ul">{`Examples include copy/paste code blocks and Terraform templates for quick setup.`}</li>
      <li parentName="ul">{`When complete, you will have an end-to-end mutual TLS deployment.`}</li>
      <li parentName="ul">{`Estimated effort: Reading time ~15 mins, Lab time ~30 to 90 mins. `}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you run into any issues please let us know <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">in GitHub Discussions</Link>.
  </div>
    </Alert>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-cli"
        }}><inlineCode parentName="a">{`step`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca"
        }}><inlineCode parentName="a">{`step-ca`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/smallstep/step-sds"
        }}><inlineCode parentName="a">{`step-sds`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/smallstep/step-aws-emojivoto"
        }}><inlineCode parentName="a">{`step-aws-emojivoto`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://aws.amazon.com/"
        }}><inlineCode parentName="a">{`aws`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://puppet.com/"
        }}><inlineCode parentName="a">{`puppet`}</inlineCode></a>{` for machine-level provisioning.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.terraform.io/"
        }}><inlineCode parentName="a">{`terraform`}</inlineCode></a>{` to configure the infrastructure.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.envoyproxy.io/"
        }}><inlineCode parentName="a">{`envoy`}</inlineCode></a></li>
    </ul>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#microservices-deployment-architecture"
        }}>{`Microservices deployment architecture`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#step-by-step-instructions"
        }}>{`Step-by-Step Instructions`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#explore-emojivoto-on-aws"
        }}>{`Explore Emojivoto on AWS`}</a></li>
    </ul>
    <h2>{`Microservices deployment architecture`}</h2>
    <p>{`This example will use automation to provision an instance of Emojivoto.`}</p>
    <pre><code parentName="pre" {...{}}>{`          +--------------+
          |    BROWSER   |
          +------|-------+
                 |
                 |TLS
                 |
          +------|-------+
          |    ENVOY     |
          |      |       |                 +------------+
          |     WEB      |                 |            |
          |      |       |    TLS+mTLS     |     CA     |
          |    ENVOY--SDS+-----------------+            |
          +------|-------+                 +-----|------+
                 |                               |
                 |                               |
                 |                               |TLS+mTLS
         mTLS    |   mTLS                        |
      +----------|----------+                    |
      |                     |                    |
      |                     |                    |
+-----|-------+       +-----|--------+           |
|   ENVOY     |       |   ENVOY      |           |
|     |       |       |              |           |
|   EMOJI--SDS|       |   VOTING--SDS+-----------+
+-----------|-+       +--------------+           |
            |                                    |
            |                                    |
            |                                    |
            +------------------------------------+
`}</code></pre>
    <ul>
      <li parentName="ul">{`Emojivoto does not support (m)TLS`}</li>
      <li parentName="ul">{`Every service in the diagram above will run on its own dedicated VM (EC2 instance) in AWS.`}</li>
      <li parentName="ul">{`An Envoy sidecar proxy (ingress and egress) per service will handle mutual TLS (authentication and encryption).`}</li>
      <li parentName="ul">{`Envoy sidecars obtain X.509 certificates through the secret discovery service (SDS) exposed via a local UNIX domain socket.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`step-sds`}</inlineCode>{` will fetch a certificate and the trust bundle (root certificate) from the internal certificate authority on behalf of each service/sidecar pair.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`step-sds`}</inlineCode>{` will handle renewals for X.509 certificates that are nearing the end of their lifetimes.`}</li>
    </ul>
    <h2>{`Step-by-Step Instructions`}</h2>
    <p>{`This AWS example integration will use full automation to provision infrastructure, machines, and services from scratch. While there are many tools available, in this exercise, we chose to use Terraform and Puppet for provisioning.`}</p>
    <p>{`Before getting started with the provisioning process, you need to configure AWS (account credentials and permissions), SSH, and Terraform.`}</p>
    <h3>{`1. Clone `}<inlineCode parentName="h3">{`step-aws-emojivoto`}</inlineCode></h3>
    <p>{`Clone the repository that has puppet and terraform files that you will use later:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`git clone https://github.com/smallstep/step-aws-emojivoto.git
`}</code></pre>
    <h3>{`2. Set up AWS CLI`}</h3>
    <p>{`Install and configure the AWS CLI. AWS has instructions for installing the CLI on various platforms at: `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-install.html"
      }}>{`https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-install.html`}</a></p>
    <p>{`Once installed, get your AWS credentials from your account or AWS IAM depending on what you're using and follow the interactive steps of the `}<inlineCode parentName="p">{`aws configure`}</inlineCode>{` command. The `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/cli/latest/userguide/cli-chap-configure.html"
      }}>{`AWS documentation`}</a>{` has detailed instructions on how to get AWS credentials.`}</p>
    <p>{`Make sure the credentials granted from the IAM policies include `}<em parentName="p">{`AmazonEC2FullAccess`}</em>{`, `}<em parentName="p">{`AmazonVPCFullAccess`}</em>{`, and `}<em parentName="p">{`AmazonRoute53FullAccess`}</em>{` (broad permissions) or at the minimum permissions as per the `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-aws-emojivoto/blob/master/policy.json"
      }}>{`policy file included in the repo`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ aws configure
AWS Access Key ID []: ****************UJ7U
AWS Secret Access Key []: ****************rUg8
Default region name []: us-west-1
Default output format []: json

$ aws s3 ls
# should list S3 buckets if the account has any
2017-10-26 13:50:39 smallstep-not-a-real-bucket
2017-10-26 15:43:20 smallstep-fake-bucket
2018-04-09 17:25:18 smallstep-nobody-home
`}</code></pre>
    <h3>{`3. Generate a SSH Key Pair`}</h3>
    <p>{`Terraform requires a key pair to be used for provisioning EC2 machine instances. Any key pair available in the respective region will work as long as the local Terraform/Puppet process has access to the key pair's private key. Please see the `}<a parentName="p" {...{
        "href": "https://docs.aws.amazon.com/AWSEC2/latest/UserGuide/ec2-key-pairs.html"
      }}>{`AWS EC2 Key Pairs documentation`}</a>{` for details on how to manage key pairs in AWS.`}</p>
    <p>{`The EC2 key pair will likely be available as a single file in `}<em parentName="p">{`PEM`}</em>{` format. Use the following commands to convert the `}<em parentName="p">{`PEM`}</em>{` and place the resulting files in locations where Terraform and Puppet can locate them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ chmod 400 aws-e2e-howto.pem
# we will need the public key in terraform config
$ ssh-keygen -f aws-e2e-howto.pem -y > ~/.ssh/terraform.pub
# the private key is already in the correct format
$ cp aws-e2e-howto.pem ~/.ssh/terraform
# new files only readable by owner (not encrypted on disk!)
$ chmod 400 ~/.ssh/terraform*
`}</code></pre>
    <p><strong parentName="p">{`Note`}</strong>{`: It's not required to use key pairs generated by AWS. `}<inlineCode parentName="p">{`ssh-keygen`}</inlineCode>{` or `}<inlineCode parentName="p">{`step`}</inlineCode>{` will work if you are familiar with or prefer local key generation.`}</p>
    <h3>{`4. Run Terraform`}</h3>
    <p>{`Terraform uses a backend hosted by `}<a parentName="p" {...{
        "href": "https://app.terraform.io/session"
      }}>{`HashiCorp`}</a>{` to store state information about managed infrastructure as well as manage concurrency locks to allow only one team member to perform changes at a time. The CLI needs a user configuration as outlined below. Create a user account and organization at `}<a parentName="p" {...{
        "href": "https://app.terraform.io/session"
      }}>{`app.terraform.io`}</a>{` and get a user token. For more details, see `}<a parentName="p" {...{
        "href": "https://www.terraform.io/docs/commands/cli-config.html"
      }}>{`HashiCorp's Terraform CLI docs`}</a>{`.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{`: Terraform won't strictly require a backend when being used by a single developer/operator`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cat ~/.terraformrc
credentials "app.terraform.io" {
  token = "<terraform user token goes here>"
}
`}</code></pre>
    <p>{`Once the `}<em parentName="p">{`~/.terraformrc`}</em>{` is in place, the Terraform backend needs to be initialized. Before running `}<inlineCode parentName="p">{`terraform init`}</inlineCode>{`, Terraform needs to be configured with the proper workplace, organization, and SSH public key.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`diff --git a/aws-emojivoto/emojivoto.tf b/aws-emojivoto/emojivoto.tf
index b510dcb..33ff92d 100644
--- a/aws-emojivoto/emojivoto.tf
+++ b/aws-emojivoto/emojivoto.tf
@@ -1,9 +1,9 @@
 terraform {
   backend "remote" {
-    organization = "Smallstep"
+    organization = "<my org>"\\n
     workspaces {
-      name = "Emojivoto"
+      name = "<my workspace: e.g. Step-AWS-Integration>"
     }
   }
 }
@@ -17,7 +17,7 @@ provider "aws" {
 # Create an SSH key pair to connect to our instances
 resource "aws_key_pair" "terraform" {
   key_name   = "terraform-key"
-  public_key = "ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQCVEhUwiAivgdFuu5rOv8ArAMqTA6N56yd5RA+uHdaC0e4MM3TYhUOwox0fV+opE3OKLYdG2+mF/6Z4k8PgsBxLpJxdQ9XHut3A9WoqCEANVfZ7dQ0mgJs1MijIAbVg1kXgYTg/2iFN6FCO74ewAJAL2e8GqBDRkwIueKbphmO5U0mK3d/nnLK0QSFYgQGFGFHvXkeQKus+625IHifat/GTZZmhCxZBcAKzaAWB8dSaZGslaKsixy3EGiY5Gqdi5tQvt+obxZ59o4Jk352YlxhlUSxoxpeOyCiBZkexZgm+0MbeBrDuOMwg/tpcUiJ0/lVomx+dQuIX6ciKIuwnvDhx"
+  public_key = "<SSH Public Key, as in ~/.ssh/terraform.pub>"
 }

 variable "ami" {
`}</code></pre>
    <p>{`Once the AWS CLI, Terraform CLI, and definitions are in place, you can initialize the workspace on the Terraform backend:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ terraform init

Initializing the backend...

Successfully configured the backend "remote"! Terraform will automatically
use this backend unless the backend configuration changes.

Initializing provider plugins...
- Finding latest version of hashicorp/aws...
- Installing hashicorp/aws v3.3.0...
- Installed hashicorp/aws v3.3.0 (signed by HashiCorp)

The following providers do not have any version constraints in configuration,
so the latest version was installed.

To prevent automatic upgrades to new major versions that may contain breaking
changes, we recommend adding version constraints in a required_providers block
in your configuration, with the constraint strings suggested below.

* hashicorp/aws: version = "~> 3.3.0"

Terraform has been successfully initialized!
[...]
`}</code></pre>
    <p>{`Now Terraform is ready to go. The `}<inlineCode parentName="p">{`terraform apply`}</inlineCode>{` command will print out a long execution plan of all the infrastructure that will be created. Terraform will prompt for a confirmation before executing on the plan. The completion of this process can take some time.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ terraform apply

An execution plan has been generated and is shown below.
Resource actions are indicated with the following symbols:
  + create

Terraform will perform the following actions:

  # aws_instance.ca will be created
  + resource "aws_instance" "ca" {
      + ami                          = "ami-068670db424b01e9a"
      + arn                          = (known after apply)
      + associate_public_ip_address  = true
      + availability_zone            = (known after apply)
      + cpu_core_count               = (known after apply)
      + cpu_threads_per_core         = (known after apply)
      + get_password_data            = false
      + host_id                      = (known after apply)
      + id                           = (known after apply)
      + instance_state               = (known after apply)
      + instance_type                = "t2.micro"
      + ipv6_address_count           = (known after apply)
      + ipv6_addresses               = (known after apply)
      + key_name                     = "terraform-key"
      + outpost_arn                  = (known after apply)
      + password_data                = (known after apply)
      + placement_group              = (known after apply)
      + primary_network_interface_id = (known after apply)
      + private_dns                  = (known after apply)
      + private_ip                   = (known after apply)
      + public_dns                   = (known after apply)
      + public_ip                    = (known after apply)
      + secondary_private_ips        = (known after apply)
      + security_groups              = (known after apply)
      + source_dest_check            = true
      + subnet_id                    = (known after apply)
      + tags                         = {
          + "Name" = "emojivoto-ca"
        }
[...]
Plan: 25 to add, 0 to change, 0 to destroy.

Changes to Outputs:
  + ca_ip     = (known after apply)
  + emoji_ip  = (known after apply)
  + puppet_ip = (known after apply)
  + voting_ip = (known after apply)
  + web_ip    = (known after apply)

Do you want to perform these actions?
  Terraform will perform the actions described above.
  Only 'yes' will be accepted to approve.

  Enter a value: yes
`}</code></pre>
    <p>{`After some wait time, Terraform will confirm the successful completion and print out details about the newly created infrastructure:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`[...]
Apply complete! Resources: 25 added, 0 changed, 0 destroyed.

Outputs:

ca_ip = 54.215.253.52
emoji_ip = 18.144.15.156
puppet_ip = 3.101.105.64
voting_ip = 3.101.28.150
web_ip = 54.176.66.184
`}</code></pre>
    <h2>{`Explore Emojivoto on AWS`}</h2>
    <p>{`AWS Emojivoto uses internal DNS records to resolve hosts for inter-service communication. All TLS certificates are issued for the respective DNS name, like `}<em parentName="p">{`web.emojivoto.local`}</em>{` or `}<em parentName="p">{`voting.emojivoto.local`}</em>{` (see `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-aws-emojivoto/blob/master/dns.tf"
      }}>{`dns.tf`}</a>{` for details).`}</p>
    <p>{`For this to work on machines without managed external DNS, the hostname/IP
address mapping needs to be added to `}<em parentName="p">{`/etc/hosts`}</em>{` so that hostnames can be
verified against server X.509 certificates.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ cat /etc/hosts
127.0.0.1       localhost
::1             localhost

54.176.66.184   web.emojivoto.local
`}</code></pre>
    <p><strong parentName="p">{`Using step`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step certificate inspect --roots root_ca.crt --short https://web.emojivoto.local
X.509v3 TLS Certificate (ECDSA P-256) [Serial: 1969...9717]
  Subject:     web.emojivoto.local
  Issuer:      Smallstep Test Intermediate CA
  Provisioner: step-sds [ID: Z2S-...gK6U]
  Valid from:  2020-08-27T01:51:26Z
          to:  2020-08-28T01:51:26Z
`}</code></pre>
    <p><strong parentName="p">{`Using cURL`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ curl -I --cacert root_ca.crt https://web.emojivoto.local
HTTP/1.1 200 OK
content-type: text/html
date: Thu, 27 Aug 2020 01:54:55 GMT
content-length: 560
x-envoy-upstream-service-time: 0
server: envoy

# without --cacert specifying the root cert it will fail (expected)
$ curl -I https://web.emojivoto.local
curl: (60) SSL certificate problem: unable to get local issuer certificate
More details here: https://curl.haxx.se/docs/sslcerts.html

curl failed to verify the legitimacy of the server and therefore could not
establish a secure connection to it. To learn more about this situation and
how to fix it, please visit the web page mentioned above.
`}</code></pre>
    <p><strong parentName="p">{`Using a browser`}</strong></p>
    <p>{`Navigating a browser to `}<em parentName="p"><a parentName="em" {...{
          "href": "https://web.emojivoto.local/"
        }}>{`https://web.emojivoto.local/`}</a></em>{` will result in a big alert warning that `}<inlineCode parentName="p">{`Your connection is not private`}</inlineCode>{`. The reason for the alert is `}<inlineCode parentName="p">{`NET::ERR_CERT_AUTHORITY_INVALID`}</inlineCode>{` which a TLS error code. The error code means that the certificate path validation could not be verified against the locally known root certificates in the trust store.`}</p>
    <p>{`Since the TLS cert for AWS Emojivoto's web service is not using `}<em parentName="p">{`Public Web PKI`}</em>{` this is expected. Beware of these warnings in production settings. In this particular case where you're using an internal CA, it's safe to click `}<inlineCode parentName="p">{`Proceed to web.emojivoto.local`}</inlineCode>{` under the `}<em parentName="p">{`Advanced`}</em>{` menu.`}</p>
    <p>{`It is possible to avoid the TLS warning by installing the internal CA's root certificate into your local trust store, `}<inlineCode parentName="p">{`step`}</inlineCode>{` has a command to do that:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ sudo step certificate install root_ca.crt
Certificate root_ca.crt has been installed.
X.509v3 Root CA Certificate (ECDSA P-256) [Serial: 1038...4951]
  Subject:     Smallstep Test Root CA
  Issuer:      Smallstep Test Root CA
  Valid from:  2019-07-12T22:14:14Z
          to:  2029-07-09T22:14:14Z
# Navigate browser to https://web.emojivoto.local without warning.

$ step certificate uninstall root_ca.crt
Certificate root_ca.crt has been removed.
X.509v3 Root CA Certificate (ECDSA P-256) [Serial: 1038...4951]
  Subject:     Smallstep Test Root CA
  Issuer:      Smallstep Test Root CA
  Valid from:  2019-07-12T22:14:14Z
          to:  2029-07-09T22:14:14Z
# Remove root cert from local trust store. Warning will reappear.
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      